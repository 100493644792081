<template>
    <div>
        <Row class="textures" type="flex" justify="center">
            <Col 
                v-for="item in 11"
                :key="item"
                :span="22" :md="18" :lg="18">
                <img :src="require(`@img/textures/t-${item}.jpg`)" alt="">
                
            </Col>
        </Row>
        <copy-right />
    </div>
</template>

<script>
import common from '@M/common';
import CopyRight from '@C/CopyRight';

export default {
    name: 'Textures',
    mixins: [common],
    components: {
        CopyRight
    }
}
</script>

<style lang="scss">
.textures {
    > div {
        margin-bottom: 2rem;

        &:first-child {
            margin-top: 5rem;
        }
        img {
            width: 100%;
            height: inherit;
        }
    }
}
</style>
